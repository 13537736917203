import React from 'react';
import IconBoxStyle11 from '../../IconBox/IconBoxStyle11';
import Spacing from '../../Spacing';

export default function ContactInfoSection({ sectionTitle }) {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0">{sectionTitle}</h2>
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5" style={{justifyContent:'center'}}>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Phone"
            subTitle="+1 (877)-346-9227"
            iconSrc="/images/contact/icon_1.svg"
          />
        </div>
        {/* <div className="col-xl-4">
          <IconBoxStyle11
            title="Email"
            subTitle="info@mycarity.us"
            iconSrc="/images/contact/icon_2.svg"
          />
        </div> */}
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Email"
            subTitle="support@mycarity.us"
            iconSrc="/images/contact/icon_2.svg"
          />
        </div>
      </div>
      {/* <Spacing md="35" /> */}
      {/* Start Google Map */}
      {/* <div className="cs_map">
        <iframe
          id="map"
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1920.902774706627!2d-149.79716012276205!3d61.21977557558442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x56c896638d4f2421%3A0x414d3cb8c5365653!2s4621%20Klondike%20Ct%2C%20Anchorage%2C%20AK%2099508%2C%20USA!5e0!3m2!1sen!2sph!4v1716267807044!5m2!1sen!2sph"
          allowFullScreen
        />
      </div> */}
      {/* End Google Map */}
    </div>
  );
}
