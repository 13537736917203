import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import Section from '../Section';
import PricingSection from '../Section/PricingSection';
import FaqSectionStyle4 from '../Section/FaqSection/FaqSectionStyle4';
import BannerSectionStyle10 from '../Section/BannerSection/BannerSectionStyle10';
const pricingData = [
  {
    title: 'Monthly',
    subTitle:
      'All subscriptions will automatically renew at the end of each billing cycle unless canceled prior to the renewal date. If you cancel your subscription, you will continue to have access to the paid benefits until the end of your current billing period. No refunds or credits will be issued for partial billing periods. Applicable sales taxes may be added to your subscription fee.',
    price: '$29',
    period: '/month',
    featureList: [
      'Job Posting Tool',
      'Task Management System',
      'Invoice Payment System',
      'Communication Tool',
      'Agreement Aggregator',
      'Access to your nearest providers',
      'Find care for all your household needs',
      'No commitment, cancel anytime',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: 'Quarterly',
    subTitle:
      'All subscriptions will automatically renew at the end of each billing cycle unless canceled prior to the renewal date. If you cancel your subscription, you will continue to have access to the paid benefits until the end of your current billing period. No refunds or credits will be issued for partial billing periods. Applicable sales taxes may be added to your subscription fee.',
    price: '$24',
    period: '/month',
    featureList: [
      'Job Posting Tool',
      'Task Management System',
      'Invoice Payment System',
      'Communication Tool',
      'Agreement Aggregator',
      'Access to your nearest providers',
      'Find care for all your household needs',
      'No commitment, cancel anytime',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: true,
  },
  {
    title: 'Yearly',
    subTitle:
      'All subscriptions will automatically renew at the end of each billing cycle unless canceled prior to the renewal date. If you cancel your subscription, you will continue to have access to the paid benefits until the end of your current billing period. No refunds or credits will be issued for partial billing periods. Applicable sales taxes may be added to your subscription fee.',
    price: '$12',
    period: '/month',
    featureList: [
      'Job Posting Tool',
      'Task Management System',
      'Invoice Payment System',
      'Communication Tool',
      'Agreement Aggregator',
      'Access to your nearest providers',
      'Find care for all your household needs',
      'No commitment, cancel anytime',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: false,
  },
  // {
  //   title: 'Dental Health Plan',
  //   subTitle:
  //     'Smile with confidence. Enjoy regular dental check-ups, emergency coverage, and exclusive discounts on dental procedures.',
  //   price: '$79',
  //   period: '/month',
  //   featureList: [
  //     'Dental Check-ups',
  //     'Procedure Discounts',
  //     'Emergency Coverage',
  //     'Oral Health Advice',
  //   ],
  //   btnText: 'Get Started',
  //   btnUrl: '/contact',
  //   popular: false,
  // },
  // {
  //   title: `Women's Health Plan`,
  //   subTitle: `Comprehensive women's health services. Receive expert gynecological care, family planning support, and prenatal/postnatal.`,
  //   price: '$169',
  //   period: '/month',
  //   featureList: [
  //     `Women's Health Services`,
  //     'Gynecological Care',
  //     'Fall Prevention Programs',
  //     'Family Planning',
  //     'Prenatal & Postnatal Support',
  //   ],
  //   btnText: 'Get Started',
  //   btnUrl: '/contact',
  //   popular: false,
  // },
  // {
  //   title: 'Sports & Fitness Plan',
  //   subTitle:
  //     'Optimized for athletes. Access sports injury assessments, physiotherapy, and expert advice for peak performance and recovery.',
  //   price: '$119',
  //   period: '/month',
  //   featureList: [
  //     'Sports Injury Assessments',
  //     'Physiotherapy Sessions',
  //     'Sports Medicine Experts',
  //     'Fitness Support',
  //   ],
  //   btnText: 'Get Started',
  //   btnUrl: '/contact',
  //   popular: false,
  // },
];
const faqData = [
  {
    title: 'What services does MyCarity offer?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'How do I book a booking with Provider?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'Do you accept insurance?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'What should I put in my booking description?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'How do I add a specific task requirements?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
];
export default function PricingPlan() {
  pageTitle('Subscription Plan');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pricing_plan/banner_bg.svg"
        imgUrl="/images/pricing_plan/banner_img.png"
        title="Find the flexible Subscription for You"
        subTitle="Explore Our Subscription Options and Start Your Journey to Better In-Home Care"
        btnText="Get Started"
        btnUrl="/contact"
      />
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <PricingSection
          sectionTitle="Choose Your In-Home Care <br />Subscription Plan"
          data={pricingData}
        />
      </Section>
      <Section
        topMd={185}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
        className="cs_gray_bg_1"
      >
        <FaqSectionStyle4
          sectionTitle="Frequently Asked <br />Questions"
          data={faqData}
          faqImgUrl="/images/home_4/faq_img.jpeg"
          spiningImgUrl="/images/home_1/about_mini.svg"
        />
      </Section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        className="cs_footer_margin_0"
      >
        <BannerSectionStyle10
          imgUrl="/images/pricing_plan/banner_img_2.png"
          title="Choose Your Subscription Today and Invest in a Better In-Home Care!"
        />
      </Section>
    </>
  );
}
