import React, {useState} from 'react';
import {checkEmailFormat, promiseWait} from '../../helpers/Utilities'
import settings from '../../setttings.json';
export default function UnsubscibeForm() {

  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event) => {

    setIsSubmitting(true);
    
    // Check if valid Email
    if(!checkEmailFormat(email)){
      alert(`Please enter valid email`);
      return;
    }

    const frmBody = {
      email : email.trim(),
    }
   
    const options = {
      crossDomain: true,
      dataType: "json",
      contentType: false,
      method: 'POST',
      body: JSON.stringify(frmBody),
    }

    promiseWait(3000).then(()=>{
      fetch(settings.unsubscibe_email_url, options)
      .then((response) => response.json())
      .then((data) => {
        console.log("RESPONSE:", data);
        alert("Successfully unsubscribed to our newsletter. Thank you!")
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("RESPONSE:", error);
        alert("We're experiencing a temporary technical issue. Please try submitting your message again later. We apologize for any inconvenience.");
        setIsSubmitting(false);
      });
    });

  };

  const SubmitBtn = () =>{
    return <>
    {
      (isSubmitting)
      ? <button className="cs_btn cs_style_1" id="btnSubmitting">
          <span>Submitting...Please wait.</span>
        </button>
      : <button className="cs_btn cs_style_1" onClick={handleSubmit} id="btnSubmit">
          <span>Submit</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
    }
      
      
    </>
  }

  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <div className="row">
        
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Email</label>
          <input
            type="email"
            className="cs_form_field"
            placeholder="example@gmail.com"
            onChange={(val)=>setEmail(val.target.value)}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        
        <div className="col-lg-12">
          <div className="cs_height_18" />
          <SubmitBtn />
        </div>
      </div>
    </div>
  );
}
